<script setup lang="ts">
const { t } = useI18n();
const localePath = useLocalePath();
const RatesStore = useRatesStore();
const { loading, refreshDate } = storeToRefs(RatesStore);
const mounted = useMounted();
const isLoading = computed(
  () => !mounted.value || !refreshDate.value || loading.value
);
</script>

<template>
  <div class="footer-top-row">
    <div class="footer-top-row-item links-row">
      <div class="links-cont">
        <FooterSocialNetworks />
      </div>
    </div>
    <div class="footer-top-row-item solidswap-row">
      <div class="logo mb-6">
        <NuxtLink
          :to="localePath('/')"
          class="display-logo decoration-none"
          flex="~"
          gap-2
          items-center
        >
          <IconLogo />
          <div class="title uppercase title-text">Solid Swap</div>
        </NuxtLink>
      </div>
      <p class="par-size-SM opacity-55 mb-6">
        {{ t("c-footer-menu-serviceDescription") }}
      </p>
      <p class="par-size-XS opacity-35">
        Solid Swap © 2024 / {{ t("l-default-rightsReserved") }}
      </p>
    </div>
    <div class="footer-top-row-item">
      <FooterMenu />
    </div>
    <div class="flex-grow"></div>
  </div>
  <el-divider />
  <div class="footer-bottom-row pb-3">
    <div class="systems-row">
      <p class="par-size-XS text-nowrap">
        <b class="system-item" :class="{ loading: isLoading }"></b>
        {{ t("c-footer-menu-systemsNormal") }}
      </p>
    </div>
    <div class="partners-row">
      <PartnerLinks />
    </div>
    <div class="flex-grow"></div>
    <div class="theme-row text-nowrap par-size-XS">
      <NuxtLink
        :to="localePath('/privicy-policy')"
        class="mr-5 decoration-none gray-link"
      >
        <span class="cursor-pointer opacity-35">
          {{ t("c-footer-menu-privacyPolicy") }}
        </span>
      </NuxtLink>
      <ClientOnly>
        <template #fallback>
          <span class="cursor-pointer opacity-35">
            {{ t("c-footer-menu-manageCookies") }}
          </span>
        </template>
        <FooterCookieManager />
      </ClientOnly>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.gray-link {
  color: var(--color-text-primary);
}
.system-item {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #3fba50;
  border-radius: 100%;
  margin-right: 8px;
  transition: all 2s;
  box-shadow: 0 0 0 4px var(--color-surface-primary), 0 0 0 5px #3fba50aa,
    var(--color-surface-primary) 0 0 0 8px, #3fba5044 0 0 0 9px;
  animation: pulse-animation-reverse 2s infinite ease-in-out;
  animation-delay: 2s;

  &.loading {
    background-color: var(--el-color-warning);
    box-shadow: 0 0 0 0 var(--color-surface-primary), 0 0 0 0 #3fba50aa,
      0 0 0 0 var(--color-surface-primary), 0 0 0 0 #3fba5044;
    animation: blink 1s infinite ease-in-out;
    animation-delay: 1s;
  }
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes pulse-animation-reverse {
  0% {
    box-shadow: 0 0 0 4px var(--color-surface-primary), 0 0 0 5px #3fba50aa,
      var(--color-surface-primary) 0 0 0 8px, #3fba5044 0 0 0 9px;
  }
  50% {
    box-shadow: 0 0 0 2px var(--color-surface-primary), 0 0 0 3px #3fba50cc,
      var(--color-surface-primary) 0 0 0 5px, #3fba5077 0 0 0 6px;
  }
  100% {
    box-shadow: 0 0 0 4px var(--color-surface-primary), 0 0 0 5px #3fba50aa,
      var(--color-surface-primary) 0 0 0 8px, #3fba5044 0 0 0 9px;
  }
}
.display-logo {
  color: var(--color-text-primary);
}
.solidswap-row,
.systems-row {
  width: 300px;
  @media (max-width: 768px) {
    width: 100%;
  }
}
.footer-top-row,
.footer-bottom-row {
  display: flex;
  gap: 70px;
  text-align: left;

  .links-row {
    order: 99;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 32px;
    .links-row {
      order: 0;
    }
  }
}

.footer-bottom-row {
  align-items: center;
}
.links-cont {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
  min-height: 175px;
  box-sizing: border-box;
  border-radius: 8px;
  background: repeating-linear-gradient(
    -65deg,
    var(--el-border-color-light),
    var(--el-border-color-light) 1px,
    var(--color-surface-secondary) 1px,
    var(--color-surface-secondary) 100px
  );
}
</style>
