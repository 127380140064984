<script setup lang="ts">
const { t } = useI18n();
const UseInitStore = useInitStore();
const { initData } = storeToRefs(UseInitStore);
const linksArr = ["instagram", "linkedin", "x", "telegram", "vk"];
const activeItem = ref("telegram");
const getNextLink = (currentLink: string) => {
  const currentIndex = linksArr.indexOf(currentLink);
  return linksArr[currentIndex + 1] || linksArr[0];
};
const { pause, resume } = useInterval(11000, {
  callback: () => {
    activeItem.value = getNextLink(activeItem.value);
  },
  controls: true,
});
const setActiveItem = (item: string) => {
  activeItem.value = item;
  pause();
};
</script>

<template>
  <div class="main-cont" :class="activeItem">
    <div>
      <a
        href="https://www.instagram.com/solid.swap"
        target="_blank"
        v-if="initData?.country !== 'RU'"
        class="instagram social-logo"
        @mouseover="setActiveItem('instagram')"
        @mouseleave="resume"
      >
        <IconInstagram />
      </a>
      <a
        href="https://www.linkedin.com/company/solid-swap"
        target="_blank"
        class="linkedin social-logo"
        @mouseover="setActiveItem('linkedin')"
        @mouseleave="resume"
      >
        <IconLinkedin />
      </a>
      <a
        href="https://x.com/SolidSwap"
        target="_blank"
        class="x social-logo"
        @mouseover="setActiveItem('x')"
        @mouseleave="resume"
      >
        <IconX />
      </a>
      <a
        href="https://t.me/solidswap_ru"
        target="_blank"
        class="telegram social-logo"
        @mouseover="setActiveItem('telegram')"
        @mouseleave="resume"
      >
        <IconTelegram />
      </a>
      <a
        href="https://vk.com/SolidSwap"
        target="_blank"
        @mouseover="setActiveItem('vk')"
        @mouseleave="resume"
        class="vk social-logo"
      >
        <IconVK />
      </a>
    </div>
    <div class="description description-instagram">
      {{ t("c-footer-menu-instagramDescription") }}
    </div>
    <div class="description description-linkedin">
      {{ t("c-footer-menu-linkedinDescription") }}
    </div>
    <div class="description description-x">
      {{ t("c-footer-menu-xDescription") }}
    </div>
    <div class="description description-telegram">
      {{ t("c-footer-menu-telegramDescription") }}
    </div>
    <div class="description description-vk">
      {{ t("c-footer-menu-vkDescription") }}
    </div>
  </div>
</template>

<style scoped lang="scss">
.main-cont {
  width: 320px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  @media (max-width: 580px) {
    width: 100%;
  }
}
.social-logos {
  white-space: nowrap;
  text-align: center;
  a {
    text-decoration: none;
    color: var(--el-text-color-primary);
  }
  @media (max-width: 580px) {
    .name {
      display: none;
    }
  }
}
.social-logo {
  max-width: 100%;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.4rem;
  color: var(--el-text-color-primary);
  opacity: 0.3;
  transition: all 0.3s;

  svg {
    width: 32px;
    height: 32px;
  }
  &.instagram svg {
    width: 26px;
    height: 26px;
  }
}

.instagram .instagram,
.linkedin .linkedin,
.x .x,
.telegram .telegram,
.vk .vk {
  opacity: 1;
}

.description {
  visibility: hidden;
  transition: none;
  opacity: 0;
  position: absolute;
  font-size: var(--par-size-XS);
}
.instagram .description-instagram,
.linkedin .description-linkedin,
.x .description-x,
.telegram .description-telegram,
.vk .description-vk {
  visibility: visible;
  opacity: 1;
  transition: all 1.8s;
  position: static;
}
</style>
