<script setup lang="ts">
import { Operation } from "@element-plus/icons-vue";
import { UserRoles } from "@/shared/enums";
const { t } = useI18n();
const { data: user } = useAuth();
const adminButton = computed(() => {
  return (
    user.value?.role === UserRoles.Admin ||
    user.value?.role === UserRoles.Moderator
  );
});

const localePath = useLocalePath();
</script>

<template>
  <div class="default-width text-center">
    <ul class="footer-menu">
      <li>
        <ul class="footer-sub-menu">
          <li>
            <NuxtLink class="no-underline" :to="localePath('/trade')">{{
              t("c-footer-menu-trade")
            }}</NuxtLink>
          </li>
          <li>
            <NuxtLink class="no-underline" :to="localePath('/trade/sell')">{{
              t("p-trade-menu-sell")
            }}</NuxtLink>
          </li>
          <li>
            <NuxtLink class="no-underline" :to="localePath('/trade/buy')">{{
              t("p-trade-menu-buy")
            }}</NuxtLink>
          </li>
          <li>
            <NuxtLink class="no-underline" :to="localePath('/exchange')">{{
              t("p-exchange-exchange")
            }}</NuxtLink>
          </li>
        </ul>
      </li>
      <li>
        <ul class="footer-sub-menu">
          <li>
            <NuxtLink class="no-underline" :to="localePath('/help')">
              Поддержка
            </NuxtLink>
          </li>
          <li>
            <NuxtLink class="no-underline" :to="localePath('/faq')">{{
              t("c-footer-menu-faq")
            }}</NuxtLink>
          </li>
          <li>
            <NuxtLink
              class="no-underline"
              :to="localePath('/beginners-guide')"
              >{{ t("c-footer-menu-beginnersGuide") }}</NuxtLink
            >
          </li>
          <li>
            <NuxtLink class="no-underline" :to="localePath('/support-chat')">
              {{ t("c-header-menu-chat") }}</NuxtLink
            >
          </li>
        </ul>
      </li>
      <li>
        <ul class="footer-sub-menu">
          <li>
            <NuxtLink class="no-underline" :to="localePath('/about')">{{
              t("c-footer-menu-about")
            }}</NuxtLink>
          </li>
          <li>
            <NuxtLink class="no-underline" :to="localePath('/news')">{{
              t("c-footer-menu-news")
            }}</NuxtLink>
          </li>
          <li>
            <NuxtLink class="no-underline" :to="localePath('/blog')">{{
              t("c-footer-menu-blog")
            }}</NuxtLink>
          </li>
        </ul>
      </li>
    </ul>
    <div class="admin-button" v-if="adminButton">
      <NuxtLink :to="localePath('/administration')">
        <el-button type="danger" :icon="Operation" size="small" round>
          {{ t("c-footer-menu-adminPanel") }}
        </el-button>
      </NuxtLink>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.footer-menu {
  padding: 0;
  max-width: 650px;
  text-align: left;
  margin-left: 0;

  li {
    display: inline-block;
    position: relative;
    vertical-align: top;
    margin-bottom: 20px;
    margin-right: 64px;

    @media (max-width: 768px) {
      margin-right: 20px;
    }
    a {
      color: var(--el-text-color-primary);
      line-height: 1;
      opacity: 0.55;
      display: inline-block;
    }
    .footer-sub-menu {
      margin-left: 0;
      li {
        display: block;
        margin-bottom: 8px;
        text-align: left;
        text-transform: capitalize;
        &:first-child {
          a {
            opacity: 1;
          }
        }
      }
    }
  }
}
.admin-button {
  position: fixed;
  bottom: 80px;
  right: 20px;
  z-index: 2001;
}
</style>
