<script setup lang="ts">
const { t } = useI18n();
const { data: user } = useAuth();
const UseInitStore = useInitStore();
const { initData } = storeToRefs(UseInitStore);
const UseLocalStore = useLocalStore();
const { cookiesSettings, tfaAlert } = storeToRefs(UseLocalStore);
const showCookiePopup = ref(false);
const showTFAPopup = ref(false);
onBeforeMount(() => {
  if (!cookiesSettings.value.settedUp && initData.value?.continent === "EU") {
    showCookiePopup.value = true;
  }
  watch(
    user,
    () => {
      if (!user.value) return;
      if (
        !user.value.tfaEnabled &&
        (!tfaAlert.value.date ||
          Date.now() - tfaAlert.value.date > 1000 * 60 * 60 * 24 ||
          tfaAlert.value.userId !== user.value.userId)
      ) {
        showTFAPopup.value = true;
      }
    },
    { immediate: true }
  );
});
</script>

<template>
  <span class="cursor-pointer opacity-35" @click="showCookiePopup = true">
    {{ t("c-footer-menu-manageCookies") }}
  </span>
  <UserTFAAlert v-model:dialogVisible="showTFAPopup" />
  <UserCookieAlert v-model:dialogVisible="showCookiePopup" />
</template>
